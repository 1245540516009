@font-face {
  font-family: 'RockSalt';
  src: url('./rocksalt.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Awakening';
  src: url('./awakenning.ttf') format('truetype');
}

html,
body {
  /* background: black; */
  background: url('./site-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0 auto;
}

.header {
  display: flex;
  margin: 0 auto;
  padding: 25px 0px;
}

.tour-logo {
  width: 250px;
  margin: 0 auto;
}

.main-content-wrapper {
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.konva-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 25px;
}

.konvajs-content {
  font-family: 'RockSalt' !important;
}

.image-editor-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 25px;
  padding-left: 25px;
}

.image-editor-field-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 12.5px;
  color: #BC271D;
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 16px;
  width: 300px;
}

input {
  background: transparent;
  color: #BC271D;
  border-width: 0px 0px 2px 0px;
  border-color: #BC271D;
  outline: none;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 300;
}

input::placeholder {
  color: #BC271D;
}

button {
  background: transparent;
  border: none;
  color: #BC271D !important;
  font-family: 'Montserrat';
  font-weight: 300;
  padding: 8px 0px;
  font-size: 16px;
}

.submit-button {
  background: transparent;
  border: 2px solid #BC271D;
  border-radius: 3px;
  padding: 0px 0px !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

.guest-buttons-row {
  flex-direction: row !important;
}

.guest-buttons-row label {
  flex: 1;
}

.guest-buttons button {
  background: rgba(0,0,0,0);
  color: white !important;
  flex-direction: row;
  flex: 1;
}

button a {
  color: black !important;
}
button:hover {
  cursor: pointer;
}

.image-editor-heading, .image-editor-instructions {
  color: #BC271D;
  font-family: 'Montserrat';
  width: 375px;
}

.image-editor-text-wrapper {
  color: #BC271D;
  font-family: 'Montserrat';
  font-size: 24px;
  text-align: center;
}

.image-editor-text-wrapper p {
  margin: 0;
}

.image-editor-text-wrapper.dig p {
  margin-top: 15px;
} 

.image-editor-text-wrapper.carve p {
  margin-bottom: 15px;
}
 
.image-editor-field-wrapper label {
  padding-bottom: 10px;
}

.image-editor-field-wrapper.guest-buttons {
  flex-direction: row;
  width: 100%;
}

.image-editor-field-wrapper.guest-buttons button {
  flex: 1;
  margin-right: 15px;
  /* width: 950px; */
}

.image-editor-field-wrapper.guest-buttons-label {
  padding-bottom: 0px;
}

.image-editor-field-wrapper.font-size {
  flex-direction: row;
  align-items: center;
}

.image-editor-field-wrapper.font-size label {
  flex: .75;
  padding-top: 11px;
}

.image-editor-field-wrapper.font-size {
  flex: 1;
}

.share-button-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.share-button-wrapper {
  background: transparent;
  border: 2px solid #BC271D;
  border-radius: 3px;
  padding: 15px 15px !important;
  /* margin-bottom: 25px; */
  /* margin-top: 25px; */
  margin: 10px 10px 0px 10px;
  text-align: center;
}

.share-button-header {
  font-family: 'Montserrat';
  color: #BC271D;
  padding: 0px 20px;
  font-size: 32px;
  font-weight: 100;
  text-align: center;
}
.reset-editor-wrapper {
  background: transparent;
  border: 2px solid #BC271D;
  border-radius: 3px;
  margin: 50px;
  text-align: center;
  padding: 15px 15px;
}
.reset-editor-wrapper button {
  padding: 0px !important;
}

.share-button-wrapper a {
  color: #BC271D !important;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Montserrat';
  margin-top: 25px !important;
}

.modal-content {
  display: none;
}

i {
  padding-right: 10px;
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }

  .main-content-wrapper {
    flex-direction: column;
    padding-top: 25px;
  }

  .konva-wrapper {
    padding-right: 0px;
  }

  .konvajs-content {
    width: 300px !important;
  }

  canvas {
    width: 300px !important;
  }

  .image-editor-heading, .image-editor-instructions {
    display: none;
  }

  .image-editor-wrapper {
    padding-left: 0px;
    width: 300px;
    padding-top: 0px;
  }

  .image-editor-field-wrapper {
    /* margin: 0 auto; */
    padding-bottom: 6px;
  }

  input {
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 18px;
  }

  .image-editor-field-wrapper.guest-buttons-row button {
    margin-right: auto;
  }

  .image-editor-field-wrapper {
    font-size: 18px;
    padding-bottom: 10px;
  }

  button {
    font-size: 18px;
    padding: 8px;
  }

  .modal-wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.6);
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    width: 85%;
    height: 300px;
    display: flex;
    top: 0;
    bottom: 0;
    border: 2px solid #BC271D;
    padding: 10px;
    border-radius: 3px;
    margin: 0 auto;
    background: black;
    color: #BC271D;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat';
  }
  
  .modal-content button {
    background: transparent;
    border: 2px solid #BC271D;
    border-radius: 3px;
    padding: 14px 0px !important;
    margin-bottom: 25px;
    margin-top: 25px;
  }
}